const WHITE = 'whitesmoke';
const BLUE = 'rgb(8, 232, 222)';
const GREEN = 'rgb(102, 255, 0)';
const YELLOW = 'rgb(255, 240, 0)';
const ORANGE = 'rgb(255, 170, 29)';
const PINK = 'rgb(255, 0, 127)';
const DARK_BLUE = 'rgb(25, 116, 210)';





module.exports = {
    WHITE, BLUE, GREEN, YELLOW, ORANGE, PINK, DARK_BLUE
}